import { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { screen } from '../types/enums';
import User from '../data/User';
import State from '../data/State';

import { sounds } from '../data/assets';

const ready = 60 * 5;

export default observer(() => {
  useEffect(() => {
    const src  = sounds.find(data => data.name === 'mainSound').src;
    State.sound = new Audio(src);
    State.sound.loop = true;
    State.getInteractive() && State.getScreen() === screen.MAIN && State.sound.play();
    document.onclick = () => {
      State.sound.paused && State.getScreen() === screen.MAIN && State.sound.play();
    }
  }, []);
  const [message, setMessage] = useState('');

  const showMessage = (text: string) => {
    if (message !== '') return; 
    setMessage(text);
    setTimeout(() => {
      setMessage('');
    }, 4000);
  }

  return (
    <div className='page'>
      <div className='friday'></div>
      <div className='menu-block-image'></div>
      <div className='menu-footer-block'>
        <div className='menu-block-buttons'>


        </div>
        <div className='footer'>
          <div className='button-blue'
            onClick={() => {
              State.setScreen(screen.READY);
              State.playSound('button');
            }}
          >ИГРАТЬ</div>
          <div className='button-red' onClick={() => {
            State.setScreen(screen.RATINGS);
          }}>ЛИДЕРЫ</div>
        </div>
      </div>
      { message !== '' && <div className='message'>{ message }</div> }
    </div>
  );
});