import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { screen } from '../types/enums';
import State from '../data/State';
import IOSTopIndent from '../components/IOSTopIndent';

import {
  sounds,
  // tina1
} from '../data/assets';

export default observer(() => {
  useEffect(() => {
    console.log('rules1')
    const src = sounds.find(data => data.name === 'rules1').src;
    State.sound = new Audio(src);
    State.sound.play();
  }, []);
  return (
    <div className='page'>
    <div className='video-block'>
      {/* <video width='100%' src={ tina1 } autoPlay playsInline></video> */}
    </div>
      <div className='header'>
        <IOSTopIndent />
        ПРИВЕТ
      </div>
      <div className='top-rules-gradient'></div>
      <div className='bottom-rules-gradient'></div>
      <div className='footer fixed-footer'>
        <div
          className='button-red'
          onClick={() => {
            State.setScreen(screen.RULES_2);
            State.playSound('button');
          }}
        >ПРАВИЛА</div>
        <div
          className='button-red'
          onClick={() => {
            State.setScreen(screen.MAIN);
            State.playSound('button');
          }}
        >В МЕНЮ</div>
      </div>
      <div className='decor-1'></div>
      <div className='decor-2'></div>
    </div>
  );
});