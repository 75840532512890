import { observer } from 'mobx-react-lite';
import { screen } from '../types/enums';
import State from '../data/State';
import IOSTopIndent from '../components/IOSTopIndent';

// import { tina2 } from '../data/assets';

export default observer(() => {
  return (
    <div className='page'>
      <div className='video-block'>
        {/* <video width='100%' src={ tina2 } autoPlay playsInline></video> */}
        <div className='rules-copyright'>Отвечай на вопросы —<br />выигрывай 10 000 рублей</div>
      </div>
      <div className='header'>
        <IOSTopIndent />
        ПРАВИЛА
      </div>
      <div className='top-rules-gradient'></div>
      <div className='bottom-rules-gradient'></div>
      <div className='footer fixed-footer'>
        <div
          className='button-red'
          onClick={() => {
            State.setScreen(screen.RULES_3);
            State.playSound('button');
          }}
        >ПОДРОБНЕЕ</div>
        <div
          className='button-red'
          onClick={() => {
            State.setScreen(screen.MAIN);
            State.playSound('button');
          }}
        >В МЕНЮ</div>
      </div>
      <div className='decor-1'></div>
      <div className='decor-2'></div>
    </div>
  );
});