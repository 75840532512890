import { makeAutoObservable } from 'mobx';
import { video } from '../types/enums';

class Session {
  constructor() {
    makeAutoObservable(this);
  }

  private _id: number;
  private _hash: string;
  private _ready: number = null;
  private _question: string;
  private _countQuestion: number;
  private _timer: number;
  private _disable: boolean;
  private _score: number;
  private _right: number = null;
  private _answer: number = null;
  private _video: video = null;
  private _categories: string;
  private _questions: Iquestion[];
  private _superQuestion: boolean = null;
  private _complete: boolean = false;
  private _finalResult: string;

  public clear(): void {
    this.clearQuestion();
    this._question = null;
    this._score = 0;
  }

  public setID(id: number): void {
    this._id = id;
  }

  public getID(): number {
    return this._id;
  }

  public getHash(): string {
    return this._hash;
  }

  public setHash(hash: string): void {
    this._hash = hash;
  }

  public getReady(): number {
    return this._ready;
  }

  public setReady(ready: number): void {
    this._ready = ready;
  }

  public getQuestion(): string {
    return this._question;
  }

  public setQuestion(question: string): void {
    this._question = question;
  }

  public getCountQuestion(): number {
    return this._countQuestion;
  }

  public plusQuestion(): void {
    this._countQuestion++;
  }

  public clearQuestion(): void {
    this._countQuestion = 1;
  }

  public getTimer(): number {
    return this._timer;
  }

  public setTimer(timer: number): void {
    this._timer = timer;
  }

  public isDisabled(): boolean {
    return this._disable;
  }

  public setDisable(disable: boolean): void {
    this._disable = disable;
  }

  public getScore(): number {
    return this._score;
  }

  public setScore(score: number): void {
    this._score = score;
  }

  public getRight(): number {
    return this._right;
  }

  public setRight(right: number): void {
    this._right = right;
  }

  public getAnswer(): number {
    return this._answer;
  }

  public setAnswer(answer: number): void {
    this._answer = answer;
  }

  public getVideo(): video {
    return this._video;
  }

  public setVideo(video: video): void {
    this._video = video;
  }

  public getCategories(): string {
    return this._categories;
  }

  public setCategories(categories: string): void {
    this._categories = categories;
  }

  public getQuestions(): Iquestion[] {
    return this._questions;
  }

  public setQuestions(questions: Iquestion[]): void {
    questions.map(question => {
      question.answers.map(answer => {
        answer.id = Number(answer.id);
      });
    })
    this._questions = questions;
  }

  public getSuperQuestion(): boolean {
    return this._superQuestion;
  }

  public setSuperQuestion(result: boolean): void {
    this._superQuestion = result;
  }

  public getComplete(): boolean {
    return this._complete;
  }

  public setComplete(complete: boolean): void {
    this._complete = complete;
  }

  public getFinalResult(): string {
    return this._finalResult;
  }

  public setFinalResult(result: string): void {
    this._finalResult = result;
  }
}

export default new Session();