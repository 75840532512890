import { observer } from 'mobx-react-lite';
import { screen } from '../types/enums';
import State from '../data/State';
import IOSTopIndent from '../components/IOSTopIndent';

export default observer(() => {
  return (
    <div className='page'>
      <div className='header'>
        <IOSTopIndent />
        ПРАВИЛА
      </div>
      <div className='rules-content'>
        <div className='rules-message'>
          <div>
            <div>Игра состоит из трёх раундов.</div>
            <div>Первый раунд — 10 вопросов.</div>
            <div>Второй раунд — Вопросы по категориям.</div>
            <div>Финальный раунд — один вопрос с открытым ответом.</div>
            <div>Баллы за все раунды суммируются. После чего ты попадаешь в рейтинг лучших из лучших.</div>
            <div>В день ты можешь сыграть только один раз. За время еженедельного розыгрыша ты можешь сыграть в каждую категорию второго раунда только один раз. После окончания еженедельного розыгрыша категории обновятся заново.</div>
            <div>Заходи в приложение каждый день, чтобы увеличить шансы на победу в еженедельном розыгрыше! Баллы за каждую игровую попытку суммируются.</div>
            <div>Каждую неделю после эфира «Умнее всех» 5 лучших игроков поделят призовой фонд в 50 000 рублей.</div>
            <div>А чтобы получить экстра-баллы, следи за эфирами «Умнее всех» на Пятнице и лови секретный супервопрос.</div>
            <a target='_blank' href="https://spec.friday.ru/docs/umnee/rules2.pdf">Правила и положения конкурса</a>
          </div>
        </div>
      </div>

      <div className='footer'>
        <div
          className='button-red'
          onClick={() => {
            State.setScreen(screen.MAIN);
            State.playSound('button');
          }}
        >В МЕНЮ</div>
      </div>
    </div>
  );
});