import { observer } from 'mobx-react-lite';
import State from '../data/State';

export default observer(() => {
  return (
    <div style={{
      color: '#fFFFFF',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      fontSize: 34,
      padding: 20,
      textAlign: 'center'
    }}>Приложение завершило работу с кодом ошибки - { State.getError() }</div>
  );
});