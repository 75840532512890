enum screen {
  LOADING,
  ERROR,
  MAIN,
  RULES_1,
  RULES_2,
  RULES_3,
  READY,
  ROUND_1,
  ROUND_2,
  ROUND_3,
  RESULT,
  RATINGS,
  SUPER_QUESTION
}
enum error {
  AUTH = 1,
  DATA = 2,
  ATTEMPTS = 3,
  SESSION = 4,
  QUESTIONS = 5,
  HASH = 6,
  CATEGORY = 7
}
enum video {
  START_MIMUTE,
  ANSWER_RIGHT,
  ANSWER_WRONG,
  LAST_ROUND_LATE,
  LAST_ROUND_RIGHT,
  LAST_ROUND_WRONG,
  RESULT_FRIDAY,
  RESULT_NOT_FRIDAY
}

export {
  screen,
  error,
  video
}