import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { screen } from '../types/enums';
import axios from 'axios';
import User from '../data/User';
import State from '../data/State';
import IOSTopIndent from '../components/IOSTopIndent';

export default observer(() => {
  const [ratings, setRatings] = useState<Irating[]>(null);
  useEffect(() => {
    axios.post(process.env.REACT_APP_API + '/getRating', {
      id: User.getUser().id,
      search: User.getSearch()
    }).then(res => {
      setRatings(res.data.data);
    });
  }, []);

  const result = ratings && ratings.map((user, key) => {
    if (key > 4) return;
    return (
      <div key={ key }>
        <div className='ratings-line'>
          <div className='ratings-avatar'></div>
          <div className='ratings-place'>{ user.place }. </div>
          <div className='ratings-name'>{ user.name }</div>
          <div className='ratings-score'>
            <div className='ratings-star'></div>
            { user.record }
          </div>
        </div>
        { key < ratings.length && <div className='ratings-hr'></div> }
      </div>
    );
  });

  const getPlace = (ratings: Irating[]) => {
    const user = ratings.find(data => data.self);
    return (
      <div className='ratings-line'>
        <div className='ratings-avatar'></div>
        <div className='ratings-place'>{ user.place }.</div>
        <div className='ratings-name'>{ user.name }</div>
        <div className='ratings-score'>
          <div className='ratings-star'></div>
          { user.record }
        </div>
      </div>
    );
  }
  const notTop = ratings && ratings.length > 5 && getPlace(ratings);

  return (
    <div className='page'>
      <IOSTopIndent />
      <div className='ratings-logo'>
        <div className='ratings-header'>РЕЙТИНГ ЛУЧШИХ</div>
      </div>
      {/* <div className='ratings-info'>{ State.getRatingCopyright() }</div> */}
      <div className='ratings-content'>
        { notTop && <div className='ratings-your'>Твой результат:</div> }
        { ratings && ratings.length > 5 && <div style={{ marginTop: 10 }} className='ratings-table'>{ notTop }</div> }
        { ratings && <div style={{ marginTop: 20 }} className='ratings-table'>{ result }</div> }
      </div>

      <div className='footer' style={{ marginTop: 10 }}>
        <div className='button-red' onClick={() => {
          State.setScreen(screen.MAIN);
          State.playSound('button');
        }}>В МЕНЮ</div>
      </div>
    </div>
  );
});