import { observer } from 'mobx-react-lite';
import Session from '../data/Session';
import Utils from '../data/Utils';
import State from '../data/State';
import { screen } from '../types/enums';

export default observer(({ round }: { round: 1 | 2 }) => {
  const word = Utils.declension(Session.getScore());
  return (
    <div className='complete'>
      <div className='complete-block'>
        <div className='complete-header'>{ round === 1 ? 'ПЕРВЫЙ РАУНД ОКОНЧЕН' : 'ВТОРОЙ РАУНД ОКОНЧЕН' }</div>
        <div className='complete-result'>Твой результат</div>
        <div className='complete-score'>{ Session.getScore() } { word }</div>
      </div>
      <div className='footer'>
        <div className='button-red' onClick={() => {
          Session.clearQuestion();
          Session.setVideo(null);
          Session.setDisable(false);
          Session.setComplete(false);
          Session.setQuestion(null);
          State.setScreen(round === 1 ? screen.ROUND_2 : screen.ROUND_3);
          // State.playSound('button');
        }}>ДАЛЕЕ</div>
      </div>
    </div>
  );
});