import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import State from '../data/State';
import { error, screen } from '../types/enums';
import axios from 'axios';
import User from '../data/User';
import Session from '../data/Session';
import IOSTopIndent from '../components/IOSTopIndent';

export default observer(() => {
  const [disable, setDisable] = useState(false);

  return (
    <div className='page'>
      <IOSTopIndent />
      <div className='ready-logo'></div>
      <div className='ready-message'>
        <div>Ты готов начать?</div>
      </div>
      <div className='ready-buttons'>
        <div
          style={{ marginTop: 10, width: '45%', height: 110 }}
          className={ disable ? 'button-grey' : 'button-red' }
          onClick={() => {
            if (disable) return;
            setDisable(true);
            axios.post(process.env.REACT_APP_API + '/startGame', {
              id: User.getUser().id,
              search: User.getSearch()
            }).then(res => {
              if (!res.data.error) {
                Session.clear();
                Session.setHash(res.data.data.hash);
                Session.setID(res.data.data.session);
                User.setAttempts(User.getAttempts() - 1);
                State.setScreen(screen.ROUND_1);
                State.playSound('button');
              } else {
                State.setError(error.ATTEMPTS);
                State.setScreen(screen.ERROR);
              }
              setDisable(false);
            }).catch(err => {
              State.setError(error.ATTEMPTS);
              State.setScreen(screen.ERROR);
            });
          }}
        >ДА</div>
        <div
          style={{ marginTop: 20, width: '45%', height: 110 }}
          className={ disable ? 'button-grey' : 'button-red' }
          onClick={() => {
            if (disable) return;
            State.setScreen(screen.MAIN);
            State.playSound('button');
          }}
        >НАЗАД</div>
      </div>
    </div>
  );
});