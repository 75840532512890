import { makeAutoObservable } from 'mobx';
import { UserInfo } from '@vkontakte/vk-bridge';

class User {
  constructor() {
    makeAutoObservable(this);
  }
  
  private _user: UserInfo = null;
  private _ban: boolean = false;
  private _attempts: number = 0;
  private _superQuetion: number;
  private _isOld: boolean = false;
  private _search: string;
  
  public getUser(): UserInfo {
    return this._user;
  }

  public setUser(user: UserInfo): void {
    this._user = user;
  }
  
  public setBan(ban: boolean): void {
    this._ban = ban;
  }

  public getBan(): boolean {
    return this._ban;
  }

  public getAttempts(): number {
    return this._attempts;
  }

  public setAttempts(attempts: number): void {
    this._attempts = attempts;
  }

  public getSuperQuestion(): number {
    return this._superQuetion;
  }

  public setSuperQuestion(question: number): void {
    this._superQuetion = question;
  }
  
  public isOld(): boolean {
    return this._isOld;
  }

  public setOld(old: boolean): void {
    this._isOld = old;
  }

  public getSearch(): string {
    return this._search;
  }

  public setSearch(search: string): void {
    this._search = search;
  }
}

export default new User();