import axios from 'axios';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { screen } from '../types/enums';
import Session from '../data/Session';
import State from '../data/State';
import User from '../data/User';
import Ready from '../components/Ready';
import CompleteFinal from '../components/CompleteFinal';

class Handler {

  private static _interval: NodeJS.Timer;

  public static ready(): void {
    Session.setTimer(20);
    Session.setReady(1);
    this._getQuestion();
  }

  private static _getQuestion(): void {
    axios.post(process.env.REACT_APP_API + '/getQuestionThirdRound', {
      id: User.getUser().id,
      session: Session.getID(),
      hash: Session.getHash(),
      search: User.getSearch()
    }).then(res => {
      if (!res.data.error) {
        const question = JSON.stringify(res.data.data);
        Session.setQuestion(question);
      } else {
        State.setError(res.data.error_type);
        State.setScreen(screen.ERROR);
      }
    });
  }

  public static startTimer(): void {
    State.sounds.round3.play();
    this._interval = setInterval(() => {
      if (Session.getTimer() > 1) {
        Session.setTimer(Session.getTimer() - 1);
      } else {
        Session.setTimer(null);
        this.sendAnswer('', true);
      }
    }, 1000);
  }

  public static stopTimer(): void {
    clearInterval(this._interval);
  }

  public static sendAnswer(answer: string, time: boolean = false): void {
    if (Session.isDisabled()) return;
    State.sounds.round3.pause();
    Session.setDisable(true);
    this.stopTimer();
    answer = answer.trim();
    answer = answer.toLocaleLowerCase();

    axios.post(process.env.REACT_APP_API + '/sendQuestionThirdRound', {
      id: User.getUser().id,
      session: Session.getID(),
      hash: Session.getHash(),
      answer: answer,
      score: Session.getScore(),
      search: User.getSearch()
    }).then(res => {
      if (!res.data.error) {
        const data: IfinalResult = {
          score: res.data.data.score,
          right: res.data.data.right,
          rightAnswer: res.data.data.answer,
          answer: answer
        }
        Session.setQuestion(null);
        Session.setFinalResult(JSON.stringify(data));
        Session.setScore(Session.getScore() + res.data.data.score);
        Session.setComplete(true);
        data.right ? State.playSound('rightAnswer') : State.playSound('wrongAnswer');
      } else {
        State.setError(res.data.error_type);
        State.setScreen(screen.ERROR);
      }
    }).catch(err => window.location.reload());
  }
}

export default observer(() => {
  const [answer, setAnswer] = useState('');
  useEffect(() => {
    Handler.ready();
  }, []);

  const data = Session.getQuestion() && JSON.parse(Session.getQuestion()) as Idata;
  const quiz = data && Session.getReady() === null;
  const timeout = Session.getVideo() !== null;
  const answerClassName = Session.isDisabled() ? 'button-grey' : 'button-red';

  return (
    <div className='page'>
      { quiz && <div className='gradient'></div> }
      { quiz && <div className='round-image'></div> }
      { quiz && data && <div className='round-header'>ФИНАЛЬНЫЙ ВОПРОС</div> }
      { quiz && <div className='round-timer'>{ Session.getTimer() ? Session.getTimer() : 0 }</div> }
      { quiz && <div className='decor-1'></div> }
      { quiz && <div className='decor-5'></div> }
      { quiz && <div className='decor-3'></div> }
      { quiz && <div className='decor-4'></div> }
      { quiz && <div className='round-question-block'>
        { !timeout && <>
          <div className='question-header'>{ data.text }</div>
          <div className='round-3-answer-block'>
            <input type='text' className='input' onChange={e => setAnswer(e.target.value)} />
            <div style={{ width: '35%', height: 75 }} className={ answerClassName } onClick={() => {
              if (Session.isDisabled()) return;
              Handler.sendAnswer(answer);
            }}>ГОТОВО</div>
          </div>
        </> }
      </div> }
      { Session.getReady() !== null && <Ready round={ 3 } callback={() => {
        Handler.startTimer();
      }} /> }
      { Session.getComplete() && <CompleteFinal /> }
    </div>
  );
});