import axios from 'axios';
import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { screen } from '../types/enums';
import Session from '../data/Session';
import User from '../data/User';
import State from '../data/State';
import Ready from '../components/Ready';
import Complete from '../components/Complete';

class Handler {

  private static _interval: NodeJS.Timer;

  public static ready(): void {
    Session.setReady(1);
    this.getQuestion();
  }

  public static getQuestion(): void {
    axios.post(process.env.REACT_APP_API + '/getQuestionFirstRound', {
      id: User.getUser().id,
      session: Session.getID(),
      hash: Session.getHash(),
      search: User.getSearch()
    }).then(res => {
      if (!res.data.error) {
        const question = JSON.stringify(res.data.data);
        Session.setDisable(false);
        Session.setRight(null);
        Session.setAnswer(null);
        Session.setQuestion(question);
      } else {
        State.setError(res.data.error_type);
        State.setScreen(screen.ERROR);
      }
    });
  }

  public static startTimer(): void {
    State.sounds.timer.play();
    State.sounds.timer.volume = 0.5;
    State.sounds.timer.loop = true;
    Session.setTimer(10);
    this._interval = setInterval(() => {
      if (Session.getTimer() > 1) {
        Session.setTimer(Session.getTimer() - 1);
      } else {
        Session.setTimer(null);
        this.stopTimer();
        this.sendAnswer(0);
      }
    }, 1000);
  }

  public static stopTimer(): void {
    State.sounds.timer.pause();
    clearInterval(this._interval);
  }

  public static sendAnswer(id: number): void {
    if (Session.isDisabled()) return;
    Session.setDisable(true);
    const question = JSON.parse(Session.getQuestion()) as Iquestion;
    this.stopTimer();
    axios.post(process.env.REACT_APP_API + '/sendQuestionFirstRound', {
      id: User.getUser().id,
      session: Session.getID(),
      hash: Session.getHash(),
      question: question.question.id,
      answer: id,
      search: User.getSearch()
    }).then(res => {
      if (!res.data.error) {
        Session.setScore(Session.getScore() + res.data.data.score);
        this._flashButtons(res.data.data.right, id);
      } else {
        State.setError(res.data.error_type);
        State.setScreen(screen.ERROR);
      }
    }).catch(err => window.location.reload());
  }

  private static _flashButtons(right: number, answer: number): void {
    Session.setRight(right);
    Session.setAnswer(answer);
    const result = right === answer;
    result ? State.playSound('rightAnswer') : State.playSound('wrongAnswer');
    setTimeout(() => {
      this._setNext();
    }, 1000);
  }
  
  private static _setNext(): void {
    if (Session.getCountQuestion() === State.MAX_QUESTIONS_1_ROUND) {
      Session.setQuestion(null);
      Session.setComplete(true);
    } else {
      Session.setQuestion(null);
      Session.plusQuestion();
      this.getQuestion();
      this.startTimer();
    }
  }
}

export default observer(() => {
  useEffect(() => {
    Handler.ready();
  }, []);

  const data = Session.getQuestion() && JSON.parse(Session.getQuestion()) as Iquestion;
  const answers = data && data.answers.map(answer => {
    const className = answer.id === Session.getAnswer() && Session.getAnswer() !== Session.getRight() ? 'btn-red' :
      answer.id === Session.getRight() && Session.getAnswer() !== 0 ? 'btn-green' :
      'btn-blue';
    return (
      <div
        key={ answer.id }
        className={ 'btn ' + className }
        onClick={() => {
          // Session.setQuestion(null);
          // State.setScreen(screen.ROUND_3);
          // Handler.stopTimer();
          Handler.sendAnswer(answer.id);
        }}
      >{ answer.text }</div>
    );
  });

  const quiz = Session.getReady() === null;

  return (
    <div className='page'>
      { quiz && <div className='bottom-round-gradient'></div> }
      { quiz && <div className='round-image'></div> }
      { quiz && data && <div className='round-header'>ВОПРОС { Session.getCountQuestion() }</div> }
      { quiz && data && <div className='round-question-block'>
        <div className='question-header'>{ data.question.text }</div>
        <div className='answers-block'>{ answers }</div>
      </div> }
      { quiz && <div className='round-timer'>{ Session.getTimer() ? Session.getTimer() : 0 }</div> }
      { quiz && <div className='decor-1'></div> }
      { quiz && <div className='decor-5'></div> }
      { !quiz && <Ready round={ 1 } callback={() => {
        Handler.startTimer();
      }} /> }
      { Session.getComplete() && <Complete round={ 1 } /> }
    </div>
  );
});