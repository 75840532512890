import { observer } from 'mobx-react-lite';
import Session from '../data/Session';
import State from '../data/State';
import {
  tina3,
  tina31,
  tina41,
} from '../data/assets';

export default observer(({ round, callback }: { round: 1 | 2 | 3, callback?: Function }) => {
  const header = round === 1 ? 'ПЕРВЫЙ РАУНД' :
    round === 2 ? 'ВТОРОЙ РАУНД' :
    round === 3 ? 'ФИНАЛЬНЫЙ РАУНД' : '';

  const video = round === 1 ? tina3 :
    round === 2 ? tina31 :
    round === 3 ? tina41 : null;

  return (
    <div className='ready'>
      <div className='video-block'>
        <video width='100%' src={ video } autoPlay playsInline></video>
        <div className='ready-header'>{ header }</div>
      </div>
      <div className='top-rules-gradient'></div>
      <div className='bottom-rules-gradient'></div>
      <div className='decor-1'></div>
      <div className='decor-2'></div>
      <div className='decor-3'></div>
      <div className='decor-4'></div>
      <div className='footer fixed-footer'>
        <div className='button-red' onClick={() => {
          Session.setReady(null);
          Session.setRight(null);
          Session.setAnswer(null);
          typeof callback === 'function' && callback();
          State.playSound('button');
        }}>НАЧАТЬ</div>
      </div>
    </div>
  );
});