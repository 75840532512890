const url = process.env.REACT_APP_CLIENT;

const main = `${url}/assets/images/main.png`;
const buttonBlue = `${url}/assets/images/button-blue.png`;
const buttonRed = `${url}/assets/images/button-red.png`;
const buttonGrey = `${url}/assets/images/button-grey.png`;
const logo = `${url}/assets/images/logo.png`;
const blueHeader = `${url}/assets/images/blue-header.png`;
const avatar = `${url}/assets/images/avatar.png`;
const star = `${url}/assets/images/star.png`;
const tina = `${url}/assets/images/tina.jpg`;
const gradient = `${url}/assets/images/gradient.png`;
const bgTimer = `${url}/assets/images/bg-timer.png`;
const decor1 = `${url}/assets/images/decor-1.png`;
const decor2 = `${url}/assets/images/decor-2.png`;
const decor3 = `${url}/assets/images/decor-3.png`;
const decor4 = `${url}/assets/images/decor-4.png`;
const decor5 = `${url}/assets/images/decor-5.png`;
const bgGameTimer = `${url}/assets/images/bg-game-timer.png`;
const questions = `${url}/assets/images/questions.jpg`;
const rulesGradient = `${url}/assets/images/rules-gradient.png`;

// const tina1 = `${url}/assets/video/01.mp4`;
// const tina2 = `${url}/assets/video/02.mp4`;
const tina3 = `${url}/assets/video/03.mp4`;
const tina31 = `${url}/assets/video/31.mp4`;
const tina41 = `${url}/assets/video/41.mp4`;

const button = `${url}/assets/sounds/button.mp3`;
const rules1 = `${url}/assets/sounds/rules-1.mp3`;
const rules2 = `${url}/assets/sounds/rules-2.mp3`;
const rightAnswer = `${url}/assets/sounds/right-answer.mp3`;
const wrongAnswer = `${url}/assets/sounds/wrong-answer.mp3`;
const mainSound = `${url}/assets/sounds/main.mp3`;
const round3 = `${url}/assets/sounds/round-3.mp3`;
const timer = `${url}/assets/sounds/timer.mp3`;


const images = [
  main,
  buttonBlue,
  buttonRed,
  buttonGrey,
  logo,
  blueHeader,
  avatar,
  star,
  tina,
  gradient,
  bgTimer,
  decor1,
  decor2,
  decor3,
  decor4,
  decor5,
  bgGameTimer,
  questions,
  rulesGradient
];
const video = [
  // tina1,
  // tina2,
  tina3,
  tina31,
  tina41
];
const sounds = [
  { src: button, name: 'button' },
  { src: rules1, name: 'rules1' },
  { src: rules2, name: 'rules2' },
  { src: rightAnswer, name: 'rightAnswer' },
  { src: wrongAnswer, name: 'wrongAnswer' },
  { src: mainSound, name: 'mainSound' },
  { src: round3, name: 'round3' },
  { src: timer, name: 'timer' }
];

export {
  images,
  video,
  sounds,

  main,
  buttonBlue,
  buttonRed,
  buttonGrey,
  logo,
  blueHeader,
  avatar,
  star,
  tina,
  gradient,
  bgTimer,
  decor1,
  decor2,
  decor3,
  decor4,
  decor5,
  bgGameTimer,
  questions,

  // tina1,
  // tina2,
  tina3,
  tina31,
  tina41
}