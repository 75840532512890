import { observer } from 'mobx-react-lite';
import { screen } from '../types/enums';
import Session from '../data/Session';
import Utils from '../data/Utils';
import State from '../data/State';

export default observer(() => {
  const data = JSON.parse(Session.getFinalResult()) as IfinalResult;
  const word = Utils.declension(data.score);
  const result = data.right ? 'ВЕРНО +' + data.score + ' ' + word.toUpperCase() : 'НЕВЕРНО!';
  
  return (
    <div className='complete'>
      <div className='complete-block'>
        <div className='complete-header'>ФИНАЛЬНЫЙ РАУНД</div>
        {/* <div className='complete-result'>Твой ответ — { data.answer }</div> */}
        <div className={ data.right ? 'complete-score' : 'complete-fail' }>{ result }</div>
        { !data.right && <div className='complete-explain'>
          <div>Правильный ответ -</div>
          <div>{ data.rightAnswer.toUpperCase() }</div>
        </div> }
      </div>
      <div className='footer' style={{ marginTop: 10 }}>
        <div className='button-red' onClick={() => {
          Session.clearQuestion();
          Session.setVideo(null);
          Session.setDisable(false);
          Session.setComplete(false);
          Session.setQuestion(null);
          State.setScreen(screen.RESULT);
          // State.playSound('button');
        }}>ДАЛЕЕ</div>
      </div>
    </div>
  );
});